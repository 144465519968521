import { template as template_606c66329a3b4734a3feff14ec1e2e65 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_606c66329a3b4734a3feff14ec1e2e65(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
